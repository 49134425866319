import React from "react";
import * as styles from './not-found.module.css';

export default class NotFound extends React.Component {

    render() {
        return (
            <div className={styles.backgroundContainer}>
                <h1 className={"display-4"}>Hoppala</h1>
                <p className={"lead"}>Hier ist der Bus leider abgefahren.<br/>Die Seite konnte nicht gefunden werden.</p>
            </div>
        );
    }
}