import React from "react"
import {Helmet} from "react-helmet";

import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import NotFound from "../components/not-found/not-found";

import SocialImage from '../images/social/LogoNeu.jpg';

export default () => (
    <>
        <Helmet htmlAttributes={{"lang": "de"}}>
            {/*Primary Meta Tags*/}
            <title>Kronschl&auml;ger Reisen — 404</title>
            <meta name="title" content="Kronschl&auml;ger Reisen — 404"/>
            <meta name="description" content="Nicht gefunden"/>

            {/*Open Graph / Facebook */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.kronschlaeger-reisen.at"/>
            <meta property="og:title" content="Kronschl&auml;ger Reisen — 404"/>
            <meta property="og:description" content="Nicht gefunden"/>
            <meta property="og:image" content={`https://www.kronschlaeger-reisen.at${SocialImage}`}/>

            {/* Google Bot */}
            <meta name="robots" content="noindex,follow"/>
        </Helmet>
        <Header overlay={true}/>
        <NotFound/>
        <Footer/>
    </>
);